<template>
  <div :class="customClass" :id="elementId">
    <div class="gray_back">
      <p fz26 font-weight-500 style="padding:60px 0;text-align: center">{{ resultData.raceName }}</p>
      <!--    <c-pre>{{ resultData }}</c-pre>-->
      <div>
        <div v-for="(list,i) in centerListV2" :key="i" style="display:flex;justify-content: flex-start;align-items: flex-start;flex-wrap: wrap;">
          <div v-for="(item,j) in list" :key="j" style="min-width: 50%;max-width:100%;margin-bottom: 30px;">
            <!-- <div style="display: inline-block;">{{ item.componentName }}：</div>-->
            <!-- <div style="display: inline-block; word-wrap: break-word;word-break: normal;">{{ item.answer }}</div>-->
            <component :is="item.componentCode" :widget="item" :index="j">
<!--               <span>【{{ item.componentCode }}】</span>-->
            </component>
            <!-- <c-pre>{{ item }}</c-pre>-->
          </div>
        </div>

        <div style="display:flex;justify-content: flex-start;align-items: flex-start;flex-wrap: wrap;">
          <div style="min-width: 50%;max-width:100%;margin-bottom: 30px;">
            <div class="component-label" style="display: inline-block;">参赛项目：</div>
            <div style="display: inline-block; word-wrap: break-word;word-break: normal;">{{ resultData.itemName}}</div>
          </div>

          <div style="min-width: 50%;max-width:100%;margin-bottom: 30px;">
            <div class="component-label" style="display: inline-block;">订单状态：</div>
            <div style="display: inline-block; word-wrap: break-word;word-break: normal;">{{ resultData.orderStatus | filterOrderStatus }}</div>
          </div>

          <div style="min-width: 50%;max-width:100%;margin-bottom: 30px;">
            <div class="component-label" style="display: inline-block;">抽签状态：</div>
            <div style="display: inline-block; word-wrap: break-word;word-break: normal;">{{ resultData.drawStatus | filterDrawStatus }}</div>
          </div>

          <div style="min-width: 50%;max-width:100%;margin-bottom: 30px;" v-if="resultData.entryNumber">
            <div class="component-label" style="display: inline-block;">号码布：</div>
            <div style="display: inline-block; word-wrap: break-word;word-break: normal;">{{ resultData.entryNumber }}</div>
          </div>

        </div>
      </div>
    </div>
    <c-pre>{{ centerListV2 }}</c-pre>
  </div>
</template>

<script type="text/ecmascript-6">
import {cloneDeep, isEmpty, chunk} from 'lodash'
import SignupComponentsMixin       from './signup-components/signup-components-mixin.js'

export default {
  // extends   : {},
  mixins    : [SignupComponentsMixin],
  components: {},
  name      : 'ResultComponent',
  props     : {
    className : { type: String, default: '' },
    resultData: { type: Object, default: () => ({}) },
  },
  data() {
    return {}
  },
  watch   : {
    // someObject: { handler: function (val, oldVal) { /* ... */ }, deep: true, immediate: true },
  },
  computed: {
    centerList() {
      return this.resultData.detailRegistrationInfo.innerComponentList
    },
    centerListV2() {
      let innerComponentList = this.resultData.detailRegistrationInfo.innerComponentList
      return chunk(innerComponentList, 2)
    },
    elementId() {return `ResultComponent_${this._uid}`},
    customClass() {return ['ResultComponent', this.className]},
  },
  methods : {
    //
  },
  created() {
    // document.documentElement.scrollTop = 0
  },
  destroyed() {},
  mounted() {},

  filters: {
    filterOrderStatus(val) {
      if (val == 1) {
        return '待付款'
      }
      else if (val == 2) {
        return '已付款'
      }
      else if (val == 3) {
        return '已退款'
      }
      else if (val == 4) {
        return '已取消'
      }
      else if (val == 10) {
        return '退款审核中'
      }
      else {
        return '-'
      }
    },

    filterAuditStatus(val) {
      if (val == 0) {
        return '-' // 无需审核
      }
      else if (val == 1) {
        return '待审核'
      }
      else if (val == 2) {
        return '审核通过'
      }
      else if (val == 3) {
        return '审核拒绝'
      }
      else {
        return '-'
      }
    },

    filterDrawStatus(val) {
      if (val == 0) {
        // return '无需抽签' // 无需抽签
        return '已中签' // 无需抽签
      }
      else if (val == 1) {
        // return '待抽签'
        return '未中签'
      }
      else if (val == 2) {
        return '未中签'
      }
      else if (val == 3) {
        return '已中签'
      }
      else {
        return '-'
      }
    },
  },
}
</script>

<style scoped lang="scss" rel="stylesheet/scss">
@import "./signup-components/component.scss";

.ResultComponent {
  width            : 100%;
  background-color : #FFFFFF;
  padding          : 0 30px;
  border-radius    : 6px;
  overflow         : hidden;

  .gray_back {
    background-color : #F9F9F9;
    padding          : 0 60px 60px 60px;
  }
}
</style>

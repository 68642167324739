<template>
  <cz-collection :class="customClass" :id="elementId" find-route-name="Root" :include-route-name-list="includeRouteNameList">

    <template v-if="debugMode">
      <result-component :result-data="debugResultData"></result-component>
    </template>

    <template v-if="!debugMode">
      <section class="registration-search-container">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
          <el-form-item class="search-input" prop="numberValue">
            <p>证件号码</p>
            <el-input placeholder="请输入证件号码" v-model="ruleForm.numberValue"></el-input>
          </el-form-item>
          <el-form-item class="search-input search-input-captcha" prop="captchaCodeValue">
            <p>验证码</p>
            <el-input placeholder="请输入验证码" v-model="ruleForm.captchaCodeValue"></el-input>
            <fox-captcha v-if="machineCheckShow" type="digital" :height="46" ref="foxCaptcha"></fox-captcha>
          </el-form-item>
          <div class="search-button">
            <!--        <el-button type="primary" @click="searchResFn()">查询</el-button>-->
            <el-button type="primary" @click="reSendCode()">查询</el-button>
            <!-- <c-pre>isResultFlag={{ isResultFlag }}</c-pre>-->
            <!-- <p style="color: #65697E;font-size: 14px;">亲子跑报名结果查询请前往数字心动App在“我的报名”进行查看，谢谢您的理解。</p>-->
          </div>
        </el-form>
      </section>
      <!-- 结果 -->
      <result-component ref="view" v-if="isResultFlag" :result-data="resultData"></result-component>
      <!--      <result-component ref="view" :result-data="resultData"></result-component>-->
    </template>
  </cz-collection>
</template>

<script type="text/ecmascript-6">

  import machineCheck      from './components/machineCheck'
  import SearchExtends     from '../search-extends.js'
  import czApi             from '@/api/cz'
  import GlobalConfig      from '@/plugins/globalConfig.js'
  import Global            from '@/mixins/global.js'
  import FoxCaptcha        from 'fox-captcha'
  import ResultComponent   from './components/result-component.vue'
  import {isEmpty}         from 'lodash'
  import DebugRegisterData from './debug-register-data.json'
  // import DebugRegisterData from './debug-register-data1.json'
  // import DebugRegisterData from './debug-register-data2.json'
  // import DebugRegisterData from './debug-register-data3.json'
  // import DebugRegisterData from './debug-register-data4.json'

  export default {
    extends   : SearchExtends,
    mixins    : [GlobalConfig, Global],
    components: { machineCheck, FoxCaptcha, ResultComponent },
    name      : 'RegistrationSearch',
    props     : {
      className: { type: String, default: '' },
    },
    data() {
      return {
        debugMode: false,
        // debugMode      : true,
        debugResultData: DebugRegisterData,
        // mode: 'debug',
        ///
        ruleForm        : {
          numberValue: null,//证件号码
          // numberValue: '362226197606070020',//证件号码
        },
        rules           : {
          numberValue     : [
            { required: true, message: '请填写证件号码', trigger: 'blur' },
          ],
          captchaCodeValue: [
            { required: true, message: '请填写验证码', trigger: 'blur' },
          ],
        },
        machineCheckShow: true,
        isResultFlag    : false,
        resultData      : {
          auditStatus           : null, // integer 审核状态(0无需审核1待审核2审核通过3审核拒绝)
          birthday              : '', // string 出生日期 1970-01-01
          cardNumber            : '', // string 证件号码
          cardType              : null, // integer 证件类型，UserCardTypeEnum
          detailRegistrationInfo: {
            id                : null, // integer 报名信息编码，修改时必填
            innerComponentList: [
              // {
              //   answer              : '', // string 用户提交答案，多选用,分割，正确答案前用@标识
              //   combineComponent    : {
              //     combineAnswer      : '', // string 用户提交内容
              //     combineCode        : '', // string 代码
              //     combineContent     : '', // string 前端展示内容
              //     combineExtendAnswer: '', // string 用户提交内容，未指定field
              //     combineField       : '', // string 关联字段
              //     combineName        : '', // string 名称
              //     combineType        : null, // integer 符合条件 0-始终显示 1-指定值
              //     combineValue       : '', // string 符合条件的值
              //   }, // undefined
              //   componentCode       : '', // string 组件代码，与前端样式绑定
              //   componentField      : '', // string 组件字段(组件绑定db字段)，自定义组件没有绑定字段，进入到报名信息的json中
              //   componentId         : null, // integer 组件编码
              //   componentName       : '', // string 组件名称
              //   componentPlaceHolder: '', // string 组件占位符
              //   componentSource     : null, // integer 组件来源 1-平台 2-自定义
              //   componentTip        : '', // string 组件提示
              //   defaulted           : null, // integer 是否默认(0否 1是)
              //   disabled            : null, // integer 是否禁止用户修改
              //   optionList          : [
              //     {
              //       optionId   : null, // integer 选项编码，可以用于排序
              //       optionName : '', // string 选项名称
              //       optionValue: '', // string 选项值
              //     },
              //   ], // array 选项列表
              //   required            : null, // integer 是否必填(0否 1是)
              //   sortNumber          : null, // integer 排序序号，从小到大
              // },
            ], // array 报名表单组件列表
            statement         : {
              autographStatus: null, // integer 是否签名 0不签名 1.需要签名
              content        : '', // string 声明内容
              id             : null, // integer 主键id
              readStatus     : null, // integer 是否强制阅读 0 不强制 1 强制
              registrationId : null, // integer 报名方案id
              title          : '', // string 标题
            }, // undefined
          }, // undefined
          drawStatus            : null, // integer 抽签状态 0无需抽签1待抽签2未中签3已中签
          dressSize             : '', // string 尺码：XS、S、M、L、XL、XXL、XXXL、XXXXL、XXXXXL
          email                 : '', // string 电子邮箱
          entryNumber           : '', // string 参赛号码
          itemId                : null, // integer 赛事项编码
          itemName              : '', // string 参赛项目名称
          nationality           : '', // string 国籍，国家简称，eg：中国
          orderStatus           : null, // integer 订单状态 1:代付款  2:已付款  3:已退款  4:已取消 10:退款审核中
          raceId                : null, // integer 赛事编码
          raceName              : '', // string 参赛项目名称
          subOrderId            : '', // string 子订单ID
          userGender            : null, // integer 性别，UserGenderEnum  1:男  2:女 3:保密
          userName              : '', // string 姓名
          userPhone             : '', // string 手机号
        },
      }
    },
    watch   : {},
    computed: {
      elementId() {return `RegistrationSearch_${this._uid}`},
      customClass() {return ['RegistrationSearch', this.className]},
    },
    methods : {
      // 滑块验证成功
      reSendCode() {
        this.$refs['ruleForm'].validate((valid) => {
          let that = this
          if (valid) {
            if (!this.$refs.foxCaptcha.validate(this.ruleForm.captchaCodeValue)) {
              // this.isResultFlag = false
              this.$alert('验证码校验失败', '提示', { confirmButtonText: '确定', callback: action => { } })
              return
            }

            // window.open('https://www.baidu.com')

            czApi.changzhengWebsiteCenterApiWebsiteSearchRegister({ cardNumber: this.ruleForm.numberValue, raceId: this.$store.getters.raceId }).then((res) => {
              if (isEmpty(res.data)) {
                this.isResultFlag = false
                this.$alert('未查询到您的报名信息，请确认证件信息后再试', '暂无数据', { confirmButtonText: '确定', callback: action => { } })
              }
              else {
                let foxCaptcha = this.$refs.foxCaptcha
                foxCaptcha.init()

                this.isResultFlag = true
                this.resultData   = res.data
                this.$message({ showClose: true, message: '报名信息查询成功！', type: 'success' })
                this.slideToScroll()
              }
            }).catch(err => {
              this.isResultFlag = false
              console.log(err)
            })
          }
          else {
            return false
          }
        })
      },
      slideToScroll() {
        // document.getElementById('resultWeb').scrollIntoView({ behavior: 'smooth' })
        // document.documentElement.scrollTop = 600
        let timer = null
        timer     = setInterval(() => {
          if (document.documentElement.scrollTop < 550) {
            document.documentElement.scrollTop += 6
          }
          else {
            clearInterval(timer)
          }
        }, 0)
      },
    },
    filters : {},
    created() {
      // document.documentElement.scrollTop = 0
    },
    destroyed() {},
    mounted() {},
  }
</script>

<style scoped lang="scss" rel="stylesheet/scss">
  // registration-search
  // @import "RegistrationSearch.scss"
  .RegistrationSearch {
    background : #F1F1F1;
    padding    : 0 100px 70px;

    .registration-search-container {
      width          : 1200px;
      margin         : 0 auto;
      background     : #ffffff;
      box-sizing     : border-box;
      padding        : 100px 0;
      display        : flex;
      flex-direction : column;
      align-items    : center;

      ::v-deep .search-input .el-form-item__content {
        display         : flex;
        justify-content : center;
        align-items     : center;

        p {
          white-space  : nowrap;
          font-size    : 16px;
          font-weight  : 400;
          color        : #65697E;
          line-height  : 22px;
          margin-right : 12px;
        }
      }

      ::v-deep .search-input .el-input .el-input__inner {
        width         : 541px;
        height        : 46px;
        background    : #FFFFFF;
        border-radius : 2px;
        border        : 1px solid #E6EBF7;
      }

      ::v-deep .search-input .el-form-item__error {
        margin-left : 78px;
      }

      .search-input-captcha {
        .el-input {
          margin : 0 22px 0 16px;
        }

        ::v-deep .el-input .el-input__inner {
          width : 415px;
        }
      }

      .search-button {
        margin-top : 30px;
        text-align : center;

        .el-button {
          width         : 120px;
          height        : 44px;
          background    : #CF000D;
          border-radius : 2px;
        }
      }
    }
  }
</style>
